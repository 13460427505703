module controllers {
    export module master {
        interface ICompanyUpdateScope extends ng.IScope {
        }

        interface ICompanyUpdateScopeParams extends ng.ui.IStateParamsService {
            comId: number;
        }

        export class companyUpdateCtrl {

            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$anchorScroll',
                '$uibModal',
                'bsLoadingOverlayService',
                '$stateParams',
                'entityService',
                '$state',
                '$timeout',
                '$transitions',
                'classificationValueService',
                'countryService',
                'documentRepositoryService',
                'customAttributeService',
                'customAttributeAnswerOptionService',
                'companyService',
                'unLocationCodeService',
                'aeoTypeService'
            ];


            comId: number;
            companyMaster: interfaces.master.ICompanyMaster;

            breadCrumbDesc: string;

            isLoadingDetail: boolean = false;
            showAll: boolean = false;

            selectedTab: number = 0;

            documentRepository: interfaces.documentManagement.IDocumentRepository;
            IsDisabled: boolean = false;

            //Attribute Grid
            apiCA: uiGrid.IGridApi;
            attributeList: interfaces.applicationcore.IDropdownModel[] = [];
            attributeAnswerOptionList: interfaces.applicationcore.IDropdownModel[] = [];

            constructor(
                private $scope: ICompanyUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService,
                private $stateParams: ICompanyUpdateScopeParams,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                public countryService: interfaces.applicationcore.ICountryService,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public customAttributeService: services.master.customAttributeService,
                public customAttributeAnswerOptionService: services.master.customAttributeAnswerOptionService,
                public companyService: services.master.companyService,
                public unLocationCodeService: services.applicationcore.unLocationCodeService,
                private aeoTypeService : services.tariff.aeoTypeService
            ) {

                this.comId = $stateParams.comId;

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.loadCompany(this.comId));

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                },
                    () => {
                        return $q.all(loadPromises);
                    });
            }

            loadCompany(Id: number) {
                return this.companyService.getCompany(Id).query((data: interfaces.master.ICompanyMaster) => {

                    this.companyMaster = data;

                    if (this.comId === 0) {
                        this.breadCrumbDesc = "Create New";
                    }
                    else {
                        this.breadCrumbDesc = this.companyMaster.Code + " - " + this.companyMaster.Name;
                    }
                    this.getDocuments(this.showAll);

                    this.isLoadingDetail = true;
                    this.$timeout(() => {
                        this.gvwCompanyAttribute.data = this.companyMaster.CompanyAttributeList;
                        this.isLoadingDetail = false;
                    });


                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;

            }

            loadStatus() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.CompanyStatus
                }, (resultList) => {
                }).$promise;
            }

            public loadCompanyTypes() {
                if (this.companyMaster && this.companyMaster.Entity && this.companyMaster.Entity.Id > 0) {
                    return this.companyService.getCompanyTypeDropdownList(this.companyMaster.Entity.Id).query().$promise;
                } else {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }
            }

            loadCountries() {
                return this.countryService.getDropdownList().query({
                },
                    (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

            loadAEOTypes(){
                return this.aeoTypeService.getForDropDownByCountry(this.companyMaster.Country.Id).query({
                },
                    (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

            loadLocations(searchText: string) {
                if (this.companyMaster && this.companyMaster.Country && this.companyMaster.Country.Id > 0) {
                    return this.unLocationCodeService.getDropdownList().query({
                        countryId: this.companyMaster.Country.Id,
                        searchText: searchText
                    },
                        (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                };
            }

            countryChanged() {
                this.companyMaster.Location = null;
                this.companyMaster.AEOType = null;
            }

            companyStatusViewHistory_Click() {
                this.DisplayCompanyStatusHistory(this.companyMaster.StatusHistoryList);
            }

            copyAddress_Click() {
                this.companyMaster.Address.PostAddressLine1 = this.companyMaster.Address.StreetAddressLine1;
                this.companyMaster.Address.PostAddressLine2 = this.companyMaster.Address.StreetAddressLine2;
                this.companyMaster.Address.PostAddressLine3 = this.companyMaster.Address.StreetAddressLine3;
                this.companyMaster.Address.PostAddressLine4 = this.companyMaster.Address.StreetAddressLine4;
                this.companyMaster.Address.PostAddressPostal = this.companyMaster.Address.StreetAddressPostal;
            }


            DisplayCompanyStatusHistory(statusHistoryList: Array<interfaces.master.ICompanyStatusHistory>) {
                return this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content" >
                            <form name="companyStatusHistory">
                                <div class="gts-padding">
                                    <fieldset id="companyStatusHistory">
                                    <legend>
                                       Company Status History
                                    </legend>                                            
                                            <div class="container-fluid row">
                                                            <table class="table table-hover">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Old Description</th>
                                                                                <th>New Description</th>
                                                                                <th>Comments</th>
                                                                                <th>User</th>
                                                                                <th>Date and Time</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr ng-repeat="statusHistory in companyStatusHistoryCtrl.statusHistoryList">                    
                                                                                <td>{{statusHistory.OldStatusDescription}}</td>
                                                                                <td>{{statusHistory.NewStatusDescription}}</td>
                                                                                <td>{{statusHistory.Comments}}</td>
                                                                                <td>{{statusHistory.User}}</td>
                                                                                <td>{{statusHistory.UpdateDate | momentDateFilter: "yyyy/MM/dd HH:mm:ss"}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                            </table>                     
                                             </div>  
                                            <div class="gts-padding pull-right">                                               
                                                    <button type="button" class="btn btn-primary"  ng-click="companyStatusHistoryCtrl.ok()">Ok</button>                                                   
                                            </div>                                       
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    `
                    ,
                    controller: class companyStatusHistoryCtrl {
                        statusHistoryList: Array<interfaces.master.ICompanyStatusHistory>;
                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService) {
                            this.statusHistoryList = statusHistoryList;
                        }

                        ok() {
                            this.$uibModalInstance.close();
                        }

                    },
                    controllerAs: "companyStatusHistoryCtrl",
                    size: "lg",
                    resolve: {
                    }
                }).result;
            }

            removeInlineCA(entity: interfaces.master.ICustomAttributeValue) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                }, () => {

                        return this.customAttributeService.deleteCustomAttributeValue(entity.Id).save({ Id: entity.Id }, (result) => {
                        this.generalService.displayMessageHandler(result);
                        this.$timeout(() => {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'com.update'
                            }, () => {
                                this.loadCompany(this.comId);
                            });
                        });
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    });
                });

                if (this.apiCA) {
                    this.apiCA.rowEdit.setRowsClean(this.companyMaster.CompanyAttributeList);
                }

                if (entity.Id === 0) {
                    _.remove(this.gvwCompanyAttribute.data, (o) => {
                        return o === entity;
                    });
                    return;
                }
            }

            checkBoxAttributeChanged(entity) {
                if (this.apiCA) {
                    this.apiCA.rowEdit.setRowsDirty([entity]);
                }
            }


            attributeValueChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.master.ICustomAttributeValue) {
                if (model) {
                    entity.AttributeValue = model.Description;

                    if (this.apiCA) {
                        this.apiCA.rowEdit.setRowsDirty([entity]);
                    }
                }
            }


            attributeValueOtherChanged(entity: interfaces.master.ICustomAttributeValue) {
                if (entity) {
                    if (this.apiCA) {
                        this.apiCA.rowEdit.setRowsDirty([entity]);
                    }
                }
            }

            insertCA() {
                this.companyMaster.CompanyAttributeList.push(<interfaces.master.ICustomAttributeValue>{
                    Id: 0,
                    Attribute: <interfaces.master.ICustomAttribute>{ Id: 0, AttributeType: 0, IsNotEditable: false }, 
                    CategoryType: 703,
                    CategoryKey: this.companyMaster.Id,
                    IsActive: true
                });
            }

            registerCompanyAttributeApi(gridApi: uiGrid.IGridApi) {
                this.apiCA = gridApi;
            }

            loadAttributeList() {
                return this.customAttributeService.GetCustomAttributeByCategory(703, this.companyMaster.Entity.Id).query({
                }, (resultList) => {
                    this.attributeList = resultList;
                }).$promise;
            }

            loadAttributeAnswerOptionList(id) {
                return this.customAttributeAnswerOptionService.GetDropDownListByAttribute().query({
                    attributeId: id
                }, (resultList) => {
                    this.attributeAnswerOptionList = resultList;
                }).$promise;
            }

            gvwCompanyAttribute: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableHorizontalScrollbar: 2,
                rowTemplate: '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ng-class="col.colIndex()" class="ui-grid-cell" ng-disabled="row.entity.Attribute.IsNotEditable" ui-grid-cell></div>',
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerCompanyAttributeApi(gridApi); },
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "Id",
                        cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.companyUpdateCtrl.removeInlineCA(row.entity)" ng-disabled="row.entity.Attribute.IsNotEditable" class="btn btn-warning btn-sm">
                                Delete
                            </button>
                        </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        width: 57,
                        enableCellEditOnFocus: false,
                        headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.companyUpdateCtrl.insertCA()" >
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                    },
                    {
                        name: 'Attribute',
                        displayName: '* Attribute',
                        field: 'Attribute',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents" ng-disabled="row.entity.Attribute.IsNotEditable">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" ng-disabled="row.entity.Attribute.IsNotEditable" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.companyUpdateCtrl.loadAttributeList()"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 300
                    },
                    {
                        name: 'AttributeValue',
                        displayName: '* Attribute Value',
                        field: 'CustomAttributeAnswerOption',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 57" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeValue}}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 58" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeValue}}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 556" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeNumericValue}}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 557" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeDateValue | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                        <gts-dropdown ng-if="row.entity.Attribute.AttributeType == 57" ng-disabled="row.entity.Attribute.IsNotEditable" ng-change="grid.appScope.companyUpdateCtrl.attributeValueChanged(model, row.entity)"  ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}-AttributeValueDD" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.companyUpdateCtrl.loadAttributeAnswerOptionList(row.entity.Attribute.Id)"></gts-dropdown>                                                                                                                                               
                                        
                                        <p class="input-group-sm" ng-if="row.entity.Attribute.AttributeType == 58">
                                        <input type="text" ng-change="grid.appScope.companyUpdateCtrl.attributeValueOtherChanged( row.entity)" ng-if="row.entity.Attribute.AttributeType == 58" ng-disabled="row.entity.Attribute.IsNotEditable"  ignore-wheel name="AttributeNumericValueText" ng-model="row.entity.AttributeValue" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                        </p>

                                        <p class="input-group-sm"  ng-if="row.entity.Attribute.AttributeType == 556">
                                        <input type="number" ng-change="grid.appScope.companyUpdateCtrl.attributeValueOtherChanged(row.entity)" ng-if="row.entity.Attribute.AttributeType == 556" ng-disabled="row.entity.Attribute.IsNotEditable"  ignore-wheel name="AttributeNumericValue" ng-model="row.entity.AttributeNumericValue" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" min="0" />
                                        </p>

                                        <gts-date ng-change="grid.appScope.companyUpdateCtrl.attributeValueOtherChanged(row.entity)" ng-if="row.entity.Attribute.AttributeType == 557" ng-disabled="row.entity.Attribute.IsNotEditable"  format="yyyy/MM/dd" ng-model="row.entity.AttributeDateValue" name="{{MODEL_COL_FIELD.$$hashKey}}-endDate"></gts-date>
                                </form>
                               `,
                        enableSorting: false,
                        width: 300
                    }
                    , {
                        name: "IsActive",
                        displayName: "Active",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellTemplate: `<input type="checkbox" ng-disabled="row.entity.Attribute.IsNotEditable" ng-model="row.entity.IsActive" ng-change="grid.appScope.companyUpdateCtrl.checkBoxAttributeChanged(row.entity)">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-disabled="row.entity.Attribute.IsNotEditable" ng-model="row.entity.IsActive">
                                                    </p>
                                                </form>`,
                        width: 60
                    }
                ]
            };

            SaveCompany(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();

                if (this.apiCA) {
                    this.companyMaster.CompanyAttributeList = _.map(this.apiCA.rowEdit.getDirtyRows(this.apiCA.grid), (o) => { return o.entity });
                }
                else {
                    this.companyMaster.CompanyAttributeList = [];
                }
                
                this.companyService.save().save(this.companyMaster, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);

                    if (this.apiCA) {
                        this.apiCA.rowEdit.setRowsClean(this.companyMaster.CompanyAttributeList);
                    }

                    this.comId = Number(data.ID);

                    deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                },
                    () => {
                        return this.SaveCompany().then((data: boolean) => {
                            if (data) {
                                this.loadCompany(this.comId);
                                this.$anchorScroll("topAnchor");
                                this.$state.go("auth.MasterData.Company.Update", { comId: this.comId });
                            }
                        });
                    });

            }

            OpenCompanyTab(companyType:string)
            {
                var url = ''; //'#!/RefundClaims/Update/' + this.comId;

                switch(companyType) { 
                    case 'CONSIGNEE': { 
                        url = '#!/MasterData/Consignee/ConsigneeUpdate/' 
                       break; 
                    } 
                    case 'CUSTOMER': { 
                        url = '#!/MasterData/Customer/CustomerUpdate/' 
                       break; 
                    } 
                    case 'BANK': { 
                        url = '#!/MasterData/Bank/BankUpdate/' 
                        break; 
                     }   
                     case 'CARTAGECONTRACTOR': { 
                        url = '#!/MasterData/CartageContractor/CartageContractor/' 
                        break; 
                     }      
                     case 'CUSTOMSPARTY': { 
                        url = '#!/MasterData/CustomsParty/CustomsPartyUpdate/' 
                        break; 
                     }     
                     case 'SHIPPER': { 
                        url = '#!/MasterData/Shipper/ShipperUpdate/' 
                        break; 
                     }  
                     case 'SUPPLIER': { 
                        url = '#!/MasterData/Supplier/SupplierUpdate/' 
                        break; 
                     }                                                                                                         
                    default: { 
                       //statements; 
                       break; 
                    } 
                 } 

                url = url + this.comId;
                window.open(url, '_blank');
            }


            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                },
                    () => {
                        return this.SaveCompany().then((data) => {
                            if (data) {
                                this.$state.go("^");
                            } else {
                                this.$anchorScroll("topAnchor");
                            }
                        });
                    });
            }

            Close() {
                this.$state.go("^");
            }

            getDocuments(showAll:boolean) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                },
                    () => {
                        return this.documentRepositoryService.getDocumentRepository().query({ referenceNumber: this.companyMaster.Code, systemType: Enum.EnumSystemType.Company, ownerEntityId: this.companyMaster.Entity.Id, showAll:showAll }, (documentRepository: interfaces.documentManagement.IDocumentRepository) => {
                            this.documentRepository = documentRepository;
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            refreshDocuments(showAll:boolean) {
                this.showAll = showAll;
                this.documentRepository = null;
                return this.getDocuments(showAll);
            }
        };

        angular.module("app").controller("companyUpdateCtrl", controllers.master.companyUpdateCtrl);
    }
}
